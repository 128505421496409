import {handleAxios} from 'utility/Utils'
import {PROXY} from 'configs/proxy'
import axios from 'axios'

const PostsApi = {
  //**Profile ref */
  create_profile_reference: data =>
    handleAxios(PROXY + '/profile-ref', 'post', data),
  //**Post */
  get_list_posts: (user_id, group_id = null, page, limit) =>
    handleAxios(
      PROXY + '/post',
      'get',
      null,
      {},
      {
        page: page,
        limit: limit,
        user_id: user_id ? user_id : null,
        group_id: group_id,
      },
    ),

  get_list_all_post: params => {
    return axios
      .get(PROXY + '/news-feed', {
        params,
      })
      .then(res => res.data)
  },
  get_list_all_post_profile: params => {
    return axios
      .get(PROXY + '/newsfeed-profile', {
        params,
      })
      .then(res => res.data)
  },
  get_list_all_post_tab_group: params => {
    return axios
      .get(PROXY + '/get_post_all_group_joined', {
        params,
      })
      .then(res => res.data)
  },

  create_post: data => axios.post('/post', data).then(res => res.data),
  delete_post: data =>
    axios.delete('/delete_post', {data}).then(res => res.data),
  post_management_delete: data =>
    axios.delete('/post-management-delete', { data }).then(res => res.data),
  update_post: data => axios.put('/update_post', data).then(res => res.data),
  post_pins: data => axios.post('/post_pins', data).then(res => res.data),
  update_post_pin: data => {
    axios.patch(`/post_pins/${data?.post_pin_id}`, data)
  },
  get_post_detail: params =>
    axios.get(`/post_info`, {params}).then(res => res.data),
  get_list_group_following: params =>
    axios
      .get(`/all_group_joined/${params.uid}`, {params})
      .then(res => res.data),
  //**Comment */

  get_list_replies_comment: data =>
    handleAxios(PROXY + '/get_target_comment', 'get', {}, {}, data),

  create_comment: data =>
    axios.post('/create_comment', data).then(res => res.data),

  get_list_comments_of_post: data =>
    handleAxios(PROXY + '/get_target_comment', 'get', {}, {}, data),

  edit_comment: data => handleAxios(PROXY + '/update_comment', 'put', data),

  delete_comment: data =>
    axios.delete(PROXY + '/delete_comment', {
      data,
    }),

  //**Privacy */
  get_list_privacy: () =>
    handleAxios(`/api/posts/privacies`, 'get', null, {}, {}),

  //**Post-pin */
  get_list_post_pin: uid =>
    axios.get('/post-pin', {params: {user_id: uid}}).then(res => res.data),
  get_list_hot_area: () => handleAxios(PROXY + `/hot-area`, 'get'),

  // --------- v2
  get_privacy_post: params =>
    axios.get('/get_object_view', {params}).then(res => res.data),

  get_embed_link: data =>
    axios.post('/common/embedding-links', data).then(res => res.data),
  get_type_reaction: () =>
    axios.get('/get-list-react-type').then(res => res.data),

  //**Reaction */ -- like / unlike
  react_post: data => axios.post('/react', data).then(res => res.data),
  get_user_reaction: params =>
    axios.get('/user-react', {params}).then(res => res.data),

  //**Report */
  report_service: data => handleAxios(PROXY + '/report', 'post', data),

  //**Post Bookmark*/
  post_bookmark: data => handleAxios(PROXY + '/post-bookmark', 'post', data),

  //**KYC */
  kyc_service: data => handleAxios(PROXY + '/profile/kyc', 'post', data),

  get_profile_suggest: () =>
    axios.get('/suggestion/profile-suggest').then(res => res.data),

  get_group_suggest: () =>
    axios.get('/suggestion/group-suggest').then(res => res.data),

}
export default PostsApi
