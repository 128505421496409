import {DefaultRoute} from '../router/routes'
import axios from 'axios'
import {PROXY, PROXY_STORAGE} from 'configs/proxy'
import image from 'assets/images/convert/trend-avatar-1_xxdjif.jpg'
// import heart from 'assets/images/reactions/heart-react.svg'
// import lovelove from 'assets/images/reactions/lovelove.svg'
// import haha from 'assets/images/reactions/haha.svg'
// import sad from 'assets/images/reactions/sad.svg'
// import angry from 'assets/images/reactions/angry.svg'
import heart from 'assets/images/reactions/love2.png'
import wow from 'assets/images/reactions/wow2.png'
import haha from 'assets/images/reactions/haha2.png'
import sad from 'assets/images/reactions/sad2.png'
import angry from 'assets/images/reactions/angry2.png'

//**Privacy Icon */
import AA from 'assets/images/icon-rank/AA.png'
import FA from 'assets/images/icon-rank/FA.png'
import PUM from 'assets/images/icon-rank/PUM.png'
import UM from 'assets/images/icon-rank/UM.png'
import BM from 'assets/images/icon-rank/BM.png'
import BDM from 'assets/images/icon-rank/BDM.png'
import globe from 'assets/images/icon-rank/globe-alt.svg'
import lock from 'assets/images/icon-rank/lock.svg'
import star from 'assets/images/icon-rank/star.svg'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = {month: 'short', day: 'numeric', year: 'numeric'},
) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = {month: 'short', day: 'numeric'}

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = {hour: 'numeric', minute: 'numeric'}
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () =>
  isUserLoggedIn() ? JSON.parse(localStorage.getItem('userData')) : null

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  // if (userRole === 'admin') return '/admin/role-permission/roles'
  if (userRole === 'admin') return DefaultRoute
  // if (userRole === 'member') return DefaultRoute
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#e6641f1a', // for option hover bg-color
    primary: '#e6641f', // for selected option bg-color
    neutral10: '#e6641f', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed', // for input hover border-color
  },
})

export function deviceId() {
  var navigator_info = window.navigator
  var screen_info = window.screen
  var uid = navigator_info.mimeTypes.length
  uid += navigator_info.userAgent.replace(/\D+/g, '')
  uid += navigator_info.plugins.length
  uid += screen_info.height || ''
  uid += screen_info.width || ''
  uid += screen_info.pixelDepth || ''
  return uid
}

export const handleAxios = async (
  url,
  method,
  data = null,
  headers = {},
  params = {},
) => {
  const secret_key = isUserLoggedIn() && getUserData()['secret_key']
  if (secret_key) {
    headers = {...headers, 's-key': secret_key}
  }

  axios.defaults.baseURL = PROXY

  return await axios({
    method,
    url,
    data,
    headers,
    params,
  }).then(res => res.data)
}

export const checkValidUrlImage = src => {
  return new Promise((resolve, reject) => {
    let img = new Image()
    img.onload = () => resolve(img.height)
    // img.onerror = reject
    img.src = src
  })
}

export const formatCurrencyVN = currency => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }).format(currency)
}

export const formatCurrency = currency => {
  return currency.toFixed(0).replace(/./g, function (c, i, a) {
    return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c
  })
}

export const CommaFormatted = (n) => {
  var parts = n.toString().split('.')
  const numberPart = parts[0]
  const decimalPart = parts[1]
  const thousands = /\B(?=(\d{3})+(?!\d))/g
  return numberPart.replace(thousands, ',') + (decimalPart ? '.' + decimalPart : '')
}

export const uploadMedia = (file, name, description) => {
  let resPromise
  let newFile = file
  if (!file) return ''
  return new Promise((resolve, reject) => {
    var fr = new FileReader()
    fr.onload = function () {
      // file is loaded
      var img = new Image()
      img.onload = async () => {
        //image is loaded; sizes are available
        resPromise = await imageScaled(
          file,
          img.width < 1280 ? img.width : 1280,
          img.height < 720 ? img.height : 720,
          false,
        ) // image HD
        newFile = new File([resPromise.blob], resPromise.name, {
          type: file.type,
        })

        const formData = new FormData()
        formData.append('create_by', getUserData()?.uid)
        formData.append('file', newFile)
        if (name) formData.append('name', name)
        if (description) formData.append('description', description)

        try {
          const res = await axios
            .post(`${PROXY_STORAGE}`, formData)
            .then(res => res.data)
          if (res && res.file) {
            resolve(res.file)
          }
        } catch (error) {
          resolve('')
        }
        resolve('')
      }
      img.src = fr.result // is the data URL because called with readAsDataURL
    }

    fr.readAsDataURL(file)
  })
}

export const uploadFile = async (file, isName, description) => {
  let newFile = file
  if (!file) return ''

  const formData = new FormData()
  formData.append('create_by', getUserData()?.uid)
  formData.append('file', newFile)
  formData.append('name', newFile.name)
  if (description) formData.append('description', description)

  try {
    const res = await axios
    .post(`${PROXY_STORAGE}`, formData)
    .then(res => res.data)
    if (res && res.file) {
      return isName ? {file: res.file, name: res.name} : res.file
    }
  } catch (error) {
    return ''
  }
  return ''
}

export const dataURItoBlob = dataURI => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])
  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ia], {type: mimeString})
}

export const detectURL = text => {
  // var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
  var urlRegex = /(https?:\/\/[^\s]+)/g
  return text.match(urlRegex)
}

export const detectUrlYoutube = url => {
  if (!url) return false
  let regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/
  let match = url.match(regExp)
  if (match && match[2].length === 11) {
    return true
  } else {
    return false
  }
}

export const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0)

// ***   remove key if value of key = null || ""
export const cleanObject = obj => {
  for (let attr in obj) {
    if (!obj[attr] && obj[attr] !== 0) delete obj[attr]
  }
  return obj
}

export const resetFormValidate = schema => {
  return schema.map(d => {
    if (['select', 'date-time', 'file', 'check-group'].includes(d.field)) {
      d.value = []
    } else {
      d.value = ''
    }
    return d
  })
}

export const defaultAvatar =
  'https://i.pinimg.com/474x/d8/0d/d2/d80dd2f38a99249677afa2cb58757992.jpg'

export const renderIconReact = type => {
  switch (type) {
    case 'haha':
      return haha
    case 'heart-react':
      return heart
    // case 'lovelove':
    //   return lovelove
    case 'lovelove':
      return wow
    case 'sad':
      return sad
    case 'angry':
      return angry
    default:
      return angry
  }
}

export const imgBroken = e => {
  e.target.onerror = null
  e.target.src = image
}

export const renderIconPrivacy = ic => {
  switch (ic) {
    case 'Công khai':
      return globe
    case 'Riêng tư':
      return lock
    case 'Tin nổi bật':
      return star
    case 'AA':
      return AA
    case 'FA':
      return FA
    case 'PUM':
      return PUM
    case 'UM':
      return UM
    case 'BM':
      return BM
    case 'BDM':
      return BDM
    default:
      return globe
  }
}

export const serializeAbility = privileges => {
  let data = []

  if (privileges === null) return []
  Object.keys(privileges).forEach(MODULE => {
    data.push({action: 'READ', subject: MODULE})

    let moduleKeys = Object.keys(privileges[MODULE])

    if (privileges[MODULE] && moduleKeys.length > 0) {
      moduleKeys.forEach(SUB_MODULE => {
        data.push({action: 'READ', subject: `${MODULE}_${SUB_MODULE}`})

        let subModuleKeys = Object.keys(privileges[MODULE][SUB_MODULE])

        if (privileges[MODULE][SUB_MODULE] && subModuleKeys.length > 0) {
          subModuleKeys.forEach(FEATURE => {
            let listFeatures = privileges[MODULE][SUB_MODULE][FEATURE]

            if (listFeatures && listFeatures.length > 0) {
              listFeatures.forEach(action => {
                data.push({
                  action: action.fea_code,
                  subject: action.code,
                })
              })
            }
          })
        }
      })
    }
  })

  return data
}

export const handleReducePrivileges = data => {
  return data.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        t => t.action === value.action && t.subject === value.subject,
      ),
  )
}

// ** ---------------------- Image Helper ---------------------------- ** /
// File and image helper functions.

// Supported image MIME types and corresponding file extensions.
export const SUPPORTED_IMAGE_FORMATS = [
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/svg',
  'image/svg+xml',
]
export const MIME_EXTENSIONS = ['jpg', 'gif', 'png', 'svg', 'svg']

// Get an URL from a theCard photo: either create a data: URL or return reference URL.
export function makeImageUrl(photo) {
  if (photo && typeof photo == 'object') {
    if (photo.ref) {
      return photo.ref
    }
    if (photo.data && photo.type) {
      const mime = photo.type.startsWith('image/')
        ? photo.type
        : 'image/' + photo.type
      return 'data:' + mime + ';base64,' + photo.data
    }
  }
  return null
}

// Calculate linear dimensions for scaling image down to fit under a certain size.
// Returns an object which contains destination sizes, source sizes, and offsets
// into source (when making square images).
export function fitImageSize(width, height, maxWidth, maxHeight, forceSquare) {
  // Sanitize input
  width = width | 0
  height = height | 0
  maxWidth = maxWidth | 0
  maxHeight = maxHeight | 0

  if (width <= 0 || height <= 0 || maxWidth <= 0 || maxHeight <= 0) {
    return null
  }

  if (forceSquare) {
    maxWidth = maxHeight = Math.min(maxWidth, maxHeight)
  }

  const scale = Math.min(
    Math.min(width, maxWidth) / width,
    Math.min(height, maxHeight) / height,
  )

  const size = {
    dstWidth: (width * scale) | 0,
    dstHeight: (height * scale) | 0,
  }

  if (forceSquare) {
    // Also calculate parameters for making the image square.
    size.dstWidth = size.dstHeight = Math.min(size.dstWidth, size.dstHeight)
    size.srcWidth = size.srcHeight = Math.min(width, height)
    size.xoffset = ((width - size.srcWidth) / 2) | 0
    size.yoffset = ((height - size.srcWidth) / 2) | 0
  } else {
    size.xoffset = size.yoffset = 0
    size.srcWidth = width
    size.srcHeight = height
  }
  return size
}

// Ensure file's extension matches mime content type
export function fileNameForMime(fname, mime) {
  const idx = SUPPORTED_IMAGE_FORMATS.indexOf(mime)
  if (idx < 0 || !fname) {
    // Unknown mime or empty name.
    return fname
  }
  const ext = MIME_EXTENSIONS[idx]

  const at = fname.lastIndexOf('.')
  if (at >= 0) {
    fname = fname.substring(0, at)
  }
  return fname + '.' + ext
}

// Scale uploaded image to fit under certain dimensions and byte size, optionally constraining to a square.
// Returns a promise which is resolven on success or rejected on failure.
export function imageScaled(
  fileOrBlob,
  maxWidth,
  maxHeight,
  maxSize,
  forceSquare,
) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'Anonymous'
    img.onerror = function (err) {
      reject(new Error('Image format unrecognized'))
    }
    img.onload = async function () {
      // Once the image is loaded, the URL is no longer needed.
      URL.revokeObjectURL(img.src)

      // Calculate the desired image dimensions.
      const dim = fitImageSize(
        img.width,
        img.height,
        maxWidth,
        maxHeight,
        forceSquare,
      )
      if (!dim) {
        reject(new Error('Invalid image'))
        return
      }
      let canvas = document.createElement('canvas')
      canvas.width = dim.dstWidth
      canvas.height = dim.dstHeight
      let ctx = canvas.getContext('2d')
      ctx.imageSmoothingEnabled = true
      ctx.drawImage(
        img,
        dim.xoffset,
        dim.yoffset,
        dim.srcWidth,
        dim.srcHeight,
        0,
        0,
        dim.dstWidth,
        dim.dstHeight,
      )

      const mime = SUPPORTED_IMAGE_FORMATS.includes(fileOrBlob.type)
        ? fileOrBlob.type
        : 'image/jpeg'

      // Generate blob to check size of the image.

      let blob = await new Promise(resolve => canvas.toBlob(resolve, mime))
      if (!blob) {
        reject(new Error('Unsupported image format'))
        return
      }

      // Ensure the image is not too large. Shrink the image keeping the aspect ratio.
      // Do nothing if maxsize is <= 0.
      while (maxSize > 0 && blob.length > maxSize) {
        dim.dstWidth = (dim.dstWidth * 0.70710678118) | 0
        dim.dstHeight = (dim.dstHeight * 0.70710678118) | 0
        canvas.width = dim.dstWidth
        canvas.height = dim.dstHeight
        ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(
          img,
          dim.xoffset,
          dim.yoffset,
          dim.srcWidth,
          dim.srcHeight,
          0,
          0,
          dim.dstWidth,
          dim.dstHeight,
        )
        blob = await new Promise(resolve => canvas.toBlob(resolve, mime))
      }

      canvas = null
      resolve({
        mime,
        blob,
        width: dim.dstWidth,
        height: dim.dstHeight,
        name: fileNameForMime(fileOrBlob.name, mime),
      })
    }
    img.src = URL.createObjectURL(fileOrBlob)
  })
}

// Scale and crop image according to specified dimensions.
// The coordinates are in unscaled image pixels, i.e. cut the rectangle first then scale it.
// returns a promise.
export function imageCrop(mime, objURL, left, top, width, height, scale) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'Anonymous'
    img.onerror = err => {
      reject(new Error('Image format unrecognized'))
    }
    img.onload = () => {
      // Once the image is loaded, the URL is no longer needed.
      URL.revokeObjectURL(img.src)

      let canvas = document.createElement('canvas')
      canvas.width = width * scale
      canvas.height = height * scale
      let ctx = canvas.getContext('2d')
      ctx.imageSmoothingEnabled = true
      ctx.drawImage(
        img,
        left,
        top,
        width,
        height,
        0,
        0,
        canvas.width,
        canvas.height,
      )

      mime = SUPPORTED_IMAGE_FORMATS.includes(mime) ? mime : 'image/jpeg'
      // Generate blob to check size of the image.
      canvas.toBlob(blob => {
        // Allow GC.
        canvas = null
        if (blob) {
          resolve({mime: mime, blob: blob, width: width, height: height})
        } else {
          reject(new Error('Unsupported image format'))
        }
      }, mime)
    }
    img.src = objURL
  })
}

// Convert file to base64 string.
export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = evt => {
      reject(reader.error)
    }
    reader.onload = () => {
      resolve({
        mime: file.type,
        bits: reader.result.split(',')[1],
        name: file.name,
      })
    }
    reader.readAsDataURL(file)
  })
}

// Convert Blob to base64 string. Returns a promise resolved with the base64 string and mime.
export function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = _ => {
      reject(reader.error)
    }
    reader.onload = () => {
      resolve({mime: blob.type, bits: reader.result.split(',')[1]})
    }
    reader.readAsDataURL(blob)
  })
}

// File pasted from the clipboard. It's either an inline image or a file attachment.
export function filePasted(
  event,
  onImageSuccess,
  onAttachmentSuccess,
  onError,
) {
  const items = (event.clipboardData || event.originalEvent.clipboardData || {})
    .items
  if (!items || !items.length) {
    return false
  }

  for (let i in items) {
    const item = items[i]
    if (item.kind === 'file') {
      const file = item.getAsFile()
      if (!file) {
        onError('Failed to get file object from pasted file item')
        continue
      }
      if (file.type && file.type.split('/')[0] == 'image') {
        onImageSuccess(file)
      } else {
        onAttachmentSuccess(file)
      }
      // Indicate that the pasted data contains a file.
      return true
    }
  }
  // No file found.
  return false
}

// Get mime type from data URL header.
export function getMimeType(header) {
  var mime = /^data:(image\/[-+a-z0-9.]+);base64/.exec(header)
  return mime && mime.length > 1 ? mime[1] : null
}

// Given length of a binary object in bytes, calculate the length after
// base64 encoding.
export function base64EncodedLen(n) {
  return Math.floor((n + 2) / 3) * 4
}

// Given length of a base64-encoded object, calculate decoded size of the
// pbject in bytes.
export function base64DecodedLen(n) {
  return Math.floor(n / 4) * 3
}

// Re-encode string to standard base64 encoding with padding.
// The string may be base64-URL encoded without the padding.
export function base64ReEncode(str) {
  if (str) {
    str = str.replace(/-/g, '+').replace(/_/g, '/')
    try {
      str = btoa(atob(str))
    } catch (err) {
      console.error('Failed to base64 re-encode string.', err)
      str = null
    }
  }
  return str
}

// Convert a base64 encoded string with the provided mime type into a Blob.
export function base64ToBlob(str, mime) {
  if (!str) {
    return null
  }

  try {
    // Make blob.
    const bin = atob(str)
    const length = bin.length
    const buf = new ArrayBuffer(length)
    const arr = new Uint8Array(buf)
    for (let i = 0; i < length; i++) {
      arr[i] = bin.charCodeAt(i)
    }

    return new Blob([buf], {type: mime})
  } catch (err) {
    console.error('Failed to convert base64 to blob: ', err)
  }

  return null
}

export function intArrayToBase64(arr) {
  if (!Array.isArray(arr)) {
    return null
  }
  try {
    let bin = ''
    new Uint8Array(arr).forEach(b => (bin += String.fromCharCode(b)))
    return window.btoa(bin)
  } catch (err) {}
  return null
}

export function base64ToIntArray(b64) {
  const arr = []
  try {
    const bin = window.atob(b64)
    ;[...bin].forEach(c => {
      arr.push(c.charCodeAt(0))
    })
    return arr
  } catch (err) {}
  return null
}

//** lấy danh sách id
export const pushId = (data) => {
  let array = []
  if (data?.length > 0) {
    data.map(it => {
      if (it?.request_user_id) {
        array.push(it?.request_user_id)
      }
    })
  }
  return Array.from(new Set(array))
}

export function formatViDate(time) {
  let day = new Date(time).getDate()
  let month = new Date(time).getMonth() + 1
  let year = new Date(time).getFullYear()
  let fulltime = `${day < 10 ? '0' + day : day}-${
    month < 10 ? '0' + month : month
  }-${year}`
  return fulltime
}

export function geDataFromLocalstrortByFieldName (fieldName, tableName) {
  const localStorageDataTable = JSON.parse(localStorage.getItem(tableName))
  let data
  if (fieldName && localStorageDataTable?.length > 0) {
    localStorageDataTable.map(it => {
      if (it.field == fieldName) {
        data = it.data
      }
    })
  }
  return isEmpty(data) ? {} : data
}

export function getUserName (fieldName, tableName, userId) {
  const array = geDataFromLocalstrortByFieldName(fieldName, tableName)
  let data = ''
  if (userId && !isEmpty(array)) {
    data = array?.data?.find(it => it?.uid == userId)
  }
  return data?.name
}

export function getBusinessLevelName (id) {
  const listLevel = [
    { id: 1, name: 'Khách hàng' },
    { id: 2, name: 'Trưởng phòng' },
    { id: 3, name: 'Giám Đốc' },
    { id: 4, name: '1 Sao' },
    { id: 5, name: '2 Sao' },
    { id: 6, name: '2 Sao Vàng' },
    { id: 7, name: '3 Sao' },
    { id: 8, name: '3 Sao Vàng' },
    { id: 9, name: '4 Sao' },
    { id: 10, name: '4 Sao Vàng' },
    { id: 11, name: '5 Sao' },
    { id: 12, name: '5 Sao Vàng' },
  ]

  const level = listLevel.find(level => level?.id == id)
  return level ? level?.name : ''
}
