// ** React Imports
import React, { Fragment, useState, useRef, useEffect } from 'react'

// ** Third Party Components
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'

// ** Vertical Menu Components
import VerticalMenuHeader from './VerticalMenuHeader'
import VerticalNavMenuItems from './VerticalNavMenuItems'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import {CheckCircle} from 'react-feather'
import KycForm from './kyc-form/KycForm'
import SuccessForm from './kyc-form/SuccessForm'
import {getUserData} from 'utility/Utils'
import {useDispatch} from 'react-redux'
import {handleLogout} from 'redux/authentication'
import { ProfileQuery, ProfileMutation } from 'hook/profile'
import img from 'assets/images/kyc/kyc-completed-successfully.png'

const Sidebar = props => {
  const dispatch = useDispatch()
  // ** Props
  const {menuCollapsed, menu, skin, menuData} = props

  // ** States
  const [groupOpen, setGroupOpen] = useState([])
  const [groupActive, setGroupActive] = useState([])
  const [currentActiveGroup, setCurrentActiveGroup] = useState([])
  const [activeItem, setActiveItem] = useState(null)
  const [open, setOpen] = useState(false)
  const [stepper, setStepper] = useState(0)
  const [modelKycSuccess, setModelKycSuccess] = useState(false)

  const nextStep = () => setStepper(stepper + 1)
  const kycStep = [
    {
      id: 'kyc-form',
      content: <KycForm onNext={nextStep} />,
    },
    {
      id: 'kyc-verified-form',
      content: (
        <SuccessForm
          successForm={() => {
            setOpen(false)
            dispatch(handleLogout())
          }}
        />
      ),
    },
  ]

  // ** Menu Hover State
  const [menuHover, setMenuHover] = useState(false)

  // ** Ref
  const shadowRef = useRef(null)

  // ** Function to handle Mouse Enter
  const onMouseEnter = () => {
    setMenuHover(true)
  }

  // ** Scroll Menu
  // const scrollMenu = container => {
  //   if (shadowRef && container.scrollTop > 0) {
  //     if (!shadowRef.current.classList.contains('d-block')) {
  //       shadowRef.current.classList.add('d-block')
  //     }
  //   } else {
  //     if (shadowRef.current.classList.contains('d-block')) {
  //       shadowRef.current.classList.remove('d-block')
  //     }
  //   }
  // }

  //**Get user_data */
  const user = getUserData()

  // *** Query
  const { data: profile } = ProfileQuery.useGetDetailProfile(user?.uid)

  // *** Mutation
  const { mutate: kycCloseWelcome } = ProfileMutation.useKycCloseWelcome()

  //** mở modal thông báo đã duyệt kyc
  useEffect(() => {
    if (profile?.data?.welcome) {
      setModelKycSuccess(!modelKycSuccess)
    }
  }, [profile])

  return (
    <Fragment>
      <div
        className={classnames(
          'main-menu menu-fixed menu-accordion menu-shadow',
          {
            expanded: menuHover || menuCollapsed === false,
            'menu-light': skin !== 'semi-dark' && skin !== 'dark',
            // 'menu-dark': skin === 'semi-dark' || skin === 'dark'
            'menu-dark': true,
          },
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setMenuHover(false)}
      >
        {menu ? (
          menu({...props})
        ) : (
          <Fragment>
            {/* Vertical Menu Header */}
            <VerticalMenuHeader
              setGroupOpen={setGroupOpen}
              menuHover={menuHover}
              {...props}
            />
            {/* Vertical Menu Header Shadow */}
            <div className="shadow-bottom" ref={shadowRef}></div>
            {/* Perfect Scrollbar */}
            <PerfectScrollbar
              className="main-menu-content"
              options={{wheelPropagation: false}}
              // onScrollY={container => scrollMenu(container)}
            >
              <ul className="navigation navigation-main">
                <VerticalNavMenuItems
                  items={menuData}
                  menuData={menuData}
                  menuHover={menuHover}
                  groupOpen={groupOpen}
                  activeItem={activeItem}
                  groupActive={groupActive}
                  setGroupOpen={setGroupOpen}
                  menuCollapsed={menuCollapsed}
                  setActiveItem={setActiveItem}
                  setGroupActive={setGroupActive}
                  currentActiveGroup={currentActiveGroup}
                  setCurrentActiveGroup={setCurrentActiveGroup}
                />
              </ul>

              {/*{user && !user.is_kyc && (*/}
              {/*  <>*/}
              {/*    <div className="kyc-sidebar-expanded">*/}
              {/*      <p className="mb-25 fw-bolder text-center fz-sm">*/}
              {/*        Tham gia mạng cộng đồng*/}
              {/*      </p>*/}
              {/*      <p className="mb-1 text-center">*/}
              {/*        Thực hiện KYC để tham gia và sử dụng mạng cộng đồng*/}
              {/*      </p>*/}
              {/*      <div className="text-center">*/}
              {/*        <Button.Ripple*/}
              {/*          className="d-inline-flex align-items-center"*/}
              {/*          color="warning"*/}
              {/*          onClick={() => {*/}
              {/*            setOpen(true)*/}
              {/*            setStepper(0)*/}
              {/*          }}*/}
              {/*        >*/}
              {/*          <CheckCircle />*/}
              {/*          <span className="align-middle ms-25">*/}
              {/*            Xác minh danh tính*/}
              {/*          </span>*/}
              {/*        </Button.Ripple>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="kyc-sidebar-expanded-collapse">*/}
              {/*      <p className="mb-0 d-flex align-items-center justify-content-center">*/}
              {/*        <CheckCircle />*/}
              {/*        <i className="fa-regular fa-id-card text-white fz-xl"/>*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*  </>*/}
              {/*)}*/}
            </PerfectScrollbar>
          </Fragment>
        )}
      </div>
      <Modal
        isOpen={open}
        toggle={() => setOpen(false)}
        className="modal-dialog-centered custom_modal_job"
        scrollable
      >
        <ModalHeader
          toggle={() => setOpen(false)}
          className="custom_modal_header bg-white p-0 mb-2 border-bottom pb-3"
        >
          {stepper === 0 && (
            <p className="mb-0 fz-xl fw-bolder" style={{color: '#038A96'}}>
              Xác minh danh tính
            </p>
          )}
        </ModalHeader>
        <ModalBody>
          {stepper === 0 && (
            <div className="pb-1 border-bottom mb-1">
              <p className="mb-1 fz-sm">
                Để đảm bảo tính chính chủ của tài khoản, và sử dụng tất các chức
                năng trên mạng cộng đồng, bạn cần xác thực với các loại chứng từ
                sau:
              </p>
              <ul className="kyc-title-list px-2 mb-0">
                <li className="mb-50 fw-bolder fz-sm">Số điện thoại</li>
                <li className="mb-50 fw-bolder fz-sm">Email</li>
                <li className="fw-bolder fz-sm">
                  Chứng minh nhân dân/Căn cước công dân
                </li>
              </ul>
            </div>
          )}
          {kycStep[stepper].content}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modelKycSuccess}
        toggle={() => {
          kycCloseWelcome()
          setModelKycSuccess(!modelKycSuccess)
        }}
        className="modal-dialog-centered"
      >
        <ModalHeader
          toggle={() => {
            kycCloseWelcome()
            setModelKycSuccess(!modelKycSuccess)
          }}
          className="custom_modal_header bg-white p-0 border-bottom pb-3">
        </ModalHeader>
        <ModalBody className={'modal-body px-5 pt-2'}>
          <div>
            <p className="fz-lg fw-bolder text-center mb-1">
              Chúc mừng tài khoản của bạn đã được xác nhận KYC
            </p>
            <p className="mb-0 text-center">
              Kể từ bây giờ bạn đã trở thành thành viên cấp AA trong mạng cộng đồng hSpace. Hãy hoạt động tích cực để
              đạt nhiều ưu đãi.
            </p>
            <div className={'d-flex justify-content-center align-items-center'}>
              <img src={img} alt="" className="img-fluid"/>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default Sidebar
