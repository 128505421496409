//=================== Domain LIVE ===================
export const ACCOUNT_API = 'https://account.thienthaonewalpha.com'
export const PROXY = 'https://newalpha.thienthaonewalpha.com'
// export const PROXY_STORAGE = 'https://account.thienthaonewalpha.com/uploads/mixed'



//=================== Domain TEST ===================
// API đăng nhập tài khoản newalpha
// export const ACCOUNT_API = 'https://test.account.newalpha.hspace.biz'

// API lấy thông tin user tài khoản newalpha
export const USER_INFO = ACCOUNT_API + '/user'

// API hệ thống newalpha
// export const PROXY = 'https://test.newalpha.hspace.biz'

//b-alpha
export const PROXY_STORAGE = ACCOUNT_API + '/crm_rest_api/upload_files/media'
//message
export const CHAT_HOST = 'https://newalpha.hspace.biz/chat/'
// export const DATA_CENTER =
//   'https://dev.api.gateway.balpha.hspace.biz/data_center/v1'
export const DATA_CENTER = 'https://gateway.newalpha.api.hspace.biz/data_center/v1'

// export const USER_EMAIL = 'dung.nguyen@bcaholdings.vn'

